import screens from 'core/src/layout/Screens'

import { isSameDay } from 'date-fns'

import { I18n } from 'i18n/types'
import { ParkingType, PlannerFeatureName, PlannerBookings, PlannerParkingBookings } from './types'

const PLANNER_FEATURES: PlannerFeatureName[] = ['parking', 'planning', 'catering', 'guest', 'room']

const INITIAL_BOOKINGS: PlannerBookings = {
  parking: {
    PARKING_COMMUTY: [],
    SHARVY_PARKING: [],
    VAMOS_PARKING: [],
    ZAPFLOOR_PARKING: [],
  },
  planning: [],
  catering: [],
  guest: [],
  room: [],
}

const PARKING_TYPES: ParkingType[] = ['PARKING_COMMUTY', 'SHARVY_PARKING', 'VAMOS_PARKING', 'ZAPFLOOR_PARKING']

const getParkingType = (siteFeatures?: Feature[]) => {
  if (!!siteFeatures && siteFeatures.length > 0) {
    return siteFeatures.find((f) => PARKING_TYPES.includes(f.type as ParkingType))?.type as ParkingType | undefined
  }
}

const getPlannerFeatures = (withBarclap: boolean, siteFeatures?: Feature[]): ScreenDefinition[] => {
  if (!!siteFeatures && siteFeatures.length > 0) {
    const screensList = Object.keys(screens).map((key) => screens[key])
    const parkingFeature: PlannerFeatureName[] = siteFeatures.some((f) => PARKING_TYPES.includes(f.type as ParkingType))
      ? ['parking']
      : []
    const siteFeaturesTypes = siteFeatures.map((f) => (f.type === 'SITE' ? 'planning' : f.type.toLowerCase()))
    const filteredFeaturesTypes = [...parkingFeature, ...PLANNER_FEATURES.filter((f) => siteFeaturesTypes.includes(f))]
      .filter((f) => (f === 'catering' && withBarclap) || f !== 'catering')
      .map((f) => screensList.find((s) => s.name.toLowerCase() === f))
      .filter(Boolean) as ScreenDefinition[]
    return filteredFeaturesTypes
  }
  return []
}

const formatUtcDate = (i18n: I18n, date: Date) => i18n.t('formats.utcDate', { date: date })

const isSelectedDateBooking = (selectedDate: Date, bookingDate: string) =>
  isSameDay(selectedDate, new Date(bookingDate))

const filterParkingByDate = (
  selectedDate: Date,
  parking: PlannerParkingBookings,
  parkingType?: ParkingType
): PlannerParkingBookings => {
  if (!parkingType) {
    return INITIAL_BOOKINGS.parking
  }
  switch (parkingType) {
    case 'PARKING_COMMUTY':
      return {
        ...INITIAL_BOOKINGS.parking,
        PARKING_COMMUTY: parking.PARKING_COMMUTY.filter((item) => isSelectedDateBooking(selectedDate, item.startTime)),
      }
    case 'SHARVY_PARKING':
      return {
        ...INITIAL_BOOKINGS.parking,
        SHARVY_PARKING: parking.SHARVY_PARKING.filter(
          (item) => !!item.reservationDate && isSelectedDateBooking(selectedDate, item.reservationDate)
        ),
      }
    case 'VAMOS_PARKING':
      return {
        ...INITIAL_BOOKINGS.parking,
        VAMOS_PARKING: parking.VAMOS_PARKING.filter((item) => isSelectedDateBooking(selectedDate, item.startDateTime)),
      }
    case 'ZAPFLOOR_PARKING':
      return {
        ...INITIAL_BOOKINGS.parking,
        ZAPFLOOR_PARKING: parking.ZAPFLOOR_PARKING.filter((item) =>
          isSelectedDateBooking(selectedDate, item.attributes.date_time_from)
        ),
      }
  }
}

const filterBySelectedDate = (
  selectedDate: Date,
  { parking, planning, catering, guest, room }: PlannerBookings,
  parkingType?: ParkingType
): PlannerBookings => {
  return {
    parking: filterParkingByDate(selectedDate, parking, parkingType),
    planning: planning.filter((item) => isSelectedDateBooking(selectedDate, item.attributes.date_from)),
    catering: catering.filter((item) => isSelectedDateBooking(selectedDate, item.date)),
    guest: guest.filter((item) => isSelectedDateBooking(selectedDate, item.day)),
    room: room.filter((item) => isSelectedDateBooking(selectedDate, item.start.dateTime)),
  }
}

const FULL_ZONE = 100
const ALMOST_FULL_ZONE = 70

const getZapfloorGauges = (Theme: Theme, bookings: ZapfloorZone[]) =>
  bookings.map((booking) => {
    const { hot_desk_id, desk_count, desks_taken } = booking.attributes
    const rate = Math.round((desks_taken * 100) / desk_count)
    const iconChoice: IconName =
      rate === FULL_ZONE ? 'gauge-full' : rate > ALMOST_FULL_ZONE ? 'gauge-middle' : 'gauge-empty'
    const occupancyColor =
      rate === FULL_ZONE
        ? Theme.colors.red
        : rate > ALMOST_FULL_ZONE
        ? Theme.colors.orange
        : Theme.colors.strongTurquoise
    return { id: hot_desk_id, iconChoice, occupancyColor, taken: desks_taken, max: desk_count, rate }
  })

export {
  PLANNER_FEATURES,
  INITIAL_BOOKINGS,
  getParkingType,
  getPlannerFeatures,
  formatUtcDate,
  isSelectedDateBooking,
  filterBySelectedDate,
  getZapfloorGauges,
}
