import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { useFormik } from 'formik'

import config from 'core/src/config'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'
import analytics, { analyticsKeys } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'
import {
  imageMimeTypes,
  documentMimeTypesIntervention,
  TypeNumberPeople,
  TypeAffection,
  TypeRequest,
  TypeRooms,
  TYPE_REQUEST_OPTIONS,
  TYPE_NUMBER_PEOPLE_OPTIONS,
  TYPE_AFFECTION_OPTIONS,
  isSpecificFormValid,
  formatDescriptionSpecificForm,
  findLevelWording,
  TYPE_ROOMS_OPTIONS,
} from './utils'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as formStore from 'store/form/formStore'
import * as bannersStore from 'banners/store'

import api from './api'

import RequestTypeSelector from './modal/RequestTypeSelector'
import ServicesSelector from './modal/ServicesSelector'
import FloorSelector from './modal/FloorSelector'
import RoomSelector from './modal/RoomSelector'
import UserRequestInfo from './components/UserRequestInfo'
import TimePickerInput from 'components/picker/TimePickerInput'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import ReservationTimeModal from 'planning/myPlanning/ReservationTimeModal'
import Directory from 'directory/DirectoryScreen'
import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Switch from 'components/switch/SwitchFilter'
import EmptyImagePlaceholder from 'components/image/assets/intervention_vide.svg'
import { FormTextInput, FormValuePicker, FormValuesPicker } from 'components/form/InterventionFormItems'
import ToastAlert from 'components/toast/ToastAlert'
import PickerModalContent from 'components/picker/PickerModalContent'
import Banner from 'components/banner/Banner'
import SurveyBanner from 'components/banner/SurveyBanner'

import Loader from 'react-loader-spinner'
import SVG from 'react-inlinesvg'
import { isAfter, isSameDay, addDays } from 'date-fns'
import DescriptionModal from './modal/DescriptionModal'
import RoomRequests from './modal/RoomRequests'
import { resizeImage } from 'utils/imageUtils'

type Status = 'ok' | 'loading' | 'error'

type PriorityValue = IncidentBody['priority']

interface Props {
  room?: Area
  origin?: string
  openModal?: boolean
}

export interface Attachment {
  file: File
  format: AttachmentImageFormat | AttachmentDocumentFormat
  type: 'IMAGE' | 'DOCUMENT'
  name: string
  data: string
}

export interface InterventionValues {
  description: string
  selectedLocation?: Area
  selectedCategory?: Category
  attachments: Attachment[]
  selectedFloor?: APILocation
  selectedBuilding?: APILocation
  services: IncidentService[]
  priority: PriorityValue
  // Valeurs pour les formulaires spécifiques espagnols
  // Texte libre
  entity?: string
  ceco?: string
  employeeId?: string
  carLicensePlate?: string
  visitDuration?: string
  visitorsNames?: string
  comment?: string
  company?: string
  estimatedCheckInCheckOut?: string
  visitorsNameAndSurnames?: string
  visitReason?: string
  placeNumber?: string
  numberPlate?: string
  requestReason?: string
  // Heures
  time: TimePickerDuration
  entryTime: TimePickerDuration
  departureTime: TimePickerDuration
  // Dates
  date: Date
  entryDate: Date
  departureDate: Date
  estimatedEndDate: Date
  // Sélecteur à choix unique
  numberOfPeople?: TypeNumberPeople
  affection?: TypeAffection
  typeRequest?: TypeRequest
  typeRooms?: TypeRooms
  // Annuaire
  requester?: UserData
  manager?: UserData
  employee?: UserData
  callerId?: UserData
  responsible?: UserData
  imageIA?: Attachment
}

const priorityValues: PriorityValue[] = ['UNKNOWN', 'LOW', 'MEDIUM', 'HIGH', 'SAFETY_IMPACT']
const MAX_INPUT_ENGIE = 3
const MAX_INPUT_EQUANS = 1
const NOW = new Date()

const InterventionScreen = ({ room, origin, openModal }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [status, setStatus] = React.useState<Status>('loading')
  const [isIALoading, setIALoading] = React.useState(false)
  const [floorsStatus, setFloorsStatus] = React.useState<Status>('loading')
  const [myInterventions, setMyInterventions] = React.useState<Incident[]>()
  const [noLocation, setNoLocation] = React.useState(false)
  const [floors, setFloors] = React.useState<APILocation[]>([])
  const [selectedIAFile, setSelectedIAFile] = React.useState<File>()
  const [suggestedCategories, setSuggestedCategories] = React.useState<Category[]>([])
  const [suggestedServices, setSuggestedServices] = React.useState<IncidentService[]>([])
  const [imageCategories, setImageCategories] = React.useState<ImageCategory[]>([])
  const [suggestedDescription, setSuggestedDescription] = React.useState('')
  const [gdprAccepted, setGdprAccepted] = React.useState(false)
  const [checkDescription, setCheckDescription] = React.useState(false)

  const [isFormTouched, setIsFormTouched] = React.useState(false)

  const hiddenFileInput: any = React.useRef()

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'INCIDENT'),
    [banners]
  )

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'INCIDENT'), [site])

  const withServices = React.useMemo(
    () => !!site && (site.incidentFormType === 'DEFAULT_WITH_SERVICE' || site.incidentFormType === 'FORM_SPAIN'),
    [site]
  )

  const withIAServices = React.useMemo(() => !!site && site.incidentFormType === 'SERVICES_IA', [site])

  const withIAWithoutServices = React.useMemo(() => !!site && site.incidentFormType === 'IA_WITHOUT_SERVICES', [site])

  const isFormSpain = React.useMemo(() => !!site && site.incidentFormType === 'FORM_SPAIN', [site])

  const hasMultipleBuildings = React.useMemo(() => Array.from(new Set(floors.map((f) => f.buildingId))).length > 1, [
    floors,
  ])

  const requestsSortedByDate = React.useMemo(() => {
    if (myInterventions) {
      return myInterventions
        .reduce((acc, cur) => {
          const index = acc.findIndex((r) => isSameDay(new Date(r.date), new Date(cur.date)))

          if (index > -1) {
            acc[index].interventions.push(cur)
          } else {
            acc.push({ date: cur.date, interventions: [cur] })
          }
          return acc
        }, [] as RequestByDate[])
        .sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? -1 : 0))
    }
  }, [myInterventions])

  const {
    values,
    handleChange,
    dirty,
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    initialValues,
    setValues,
  } = useFormik({
    initialValues: {
      description: '',
      services: [],
      priority: 'LOW',
      attachments: [],
      time: {
        hours: 9,
        minutes: 0,
      },
      departureTime: {
        hours: 9,
        minutes: 0,
      },
      entryTime: {
        hours: 9,
        minutes: 0,
      },
      date: NOW,
      departureDate: NOW,
      entryDate: NOW,
      estimatedEndDate: NOW,
    } as InterventionValues,
    onSubmit: (values: InterventionValues) => {
      if (
        site &&
        user &&
        values.selectedCategory &&
        (isFormSpain && !!spainFormType ? true : noLocation ? values.selectedFloor : values.selectedLocation)
      ) {
        if (!!values.imageIA && !!values.description && !checkDescription) {
          setCheckDescription(true)
          Modal.open(() => renderCheckDescription())
          return
        }
        if (checkDescription) {
          setCheckDescription(false)
        }
        setStatus('loading')

        const formData = new FormData()
        formData.append('categoryId', values.selectedCategory.categoryId)
        if (!noLocation && !!values.selectedLocation) {
          formData.append('uniqueName', values.selectedLocation.uniquePlaceName)
        }
        formData.append(
          'description',
          isFormSpain && !!spainFormType
            ? formatDescriptionSpecificForm(values, spainFormType, user, site, i18n)
            : values.description
        )
        if (values.attachments.length > 0 || !!values.imageIA) {
          const allAttachments = !!values.imageIA ? [values.imageIA, ...values.attachments] : values.attachments
          formData.append('attachmentsName', allAttachments.map((a) => a.name).toString())
          formData.append('attachmentsContent', allAttachments.map((a) => a.data.split(',').pop() || '').toString())
        }
        if (!!values.selectedFloor) {
          formData.append('floorId', values.selectedFloor.floorId)
        }
        if ((withServices || withIAServices) && !spainFormType) {
          if (!!values.priority) {
            formData.append('priority', values.priority)
          }
          formData.append('incidentServiceIds', values.services.map((s) => s.id).toString())
        }
        if (
          isFormSpain &&
          spainFormType &&
          !!user.officeId &&
          (spainFormType === 'type1' || spainFormType === 'type4')
        ) {
          formData.append(
            'requester',
            !!values.requester && !!values.requester.engieId ? values.requester.engieId : user.officeId
          )
        }

        api
          .createIncident(formData, site.id, user.type)
          .then(() => {
            if (withIAServices || withIAWithoutServices) {
              sendAnalyticsIA(true)
            }
            getUserRequests()
            resetForm()
            setSuggestedCategories([])
            setSelectedIAFile(undefined)
            setSuggestedServices([])
            setImageCategories([])
            setSuggestedDescription('')
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('screens.incident.requestRegistered')}
                onConfirm={Modal.close}
                description={i18n.t('screens.incident.registeredDescription')}
                confirmButtonTitle={i18n.t('common.close')}
              />
            ))
          })
          .catch((err) => {
            if (withIAServices || withIAWithoutServices) {
              sendAnalyticsIA(false)
            }
            setStatus('ok')
            Logger.error(err)
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('screens.incident.requestError')}
                onConfirm={Modal.close}
                confirmButtonTitle={i18n.t('common.ok')}
              />
            ))
          })
      }
    },
    validateOnChange: true,
  })

  React.useEffect(() => {
    if (!!selectedIAFile && !!site && !!user) {
      setIALoading(true)
      const formData = new FormData()
      formData.append('image', selectedIAFile)
      Promise.all([api.getParentCategories(site.id, user.type), api.suggestionImageIa(formData, site.id, user.type)])
        .then(([{ categories }, { description, imageCategories }]) => {
          if (!!description) {
            setFieldValue('description', description)
            setSuggestedDescription(description)
          }
          if (!!imageCategories) {
            setImageCategories(imageCategories)
            const allCategories = categories.reduce((acc, cur) => acc.concat(cur.subcategories), [] as Category[])
            const suggestedCategories = imageCategories.reduce((acc, cur) => {
              const findCategory = allCategories.find((cat) => cat.categoryId === cur.sysId)
              if (!!findCategory) {
                acc.push(findCategory)
              }
              return acc
            }, [] as Category[])
            setSuggestedCategories(suggestedCategories)
          }
        })
        .catch((err) => {
          Logger.error(err)
          Modal.open(() => (
            <ValidationContent
              title={i18n.t('screens.incident.requestError')}
              onConfirm={Modal.close}
              confirmButtonTitle={i18n.t('common.ok')}
              description={
                !!err.data.error && err.data.error === 'CHAT_GPT_DEFAULT_ERROR'
                  ? i18n.t('screens.incident.iaError')
                  : undefined
              }
            />
          ))
        })
        .finally(() => setIALoading(false))
    }
  }, [selectedIAFile])

  React.useEffect(() => {
    if (!room && openModal && !isFormSpain && !withIAServices && !withIAWithoutServices && floors.length > 0) {
      const hasMultipleBuildings = Array.from(new Set(floors.map((f) => f.buildingId))).length > 1
      openFloorSelector(hasMultipleBuildings ? 'BUILDING' : 'FLOOR')
    }
  }, [floors])

  React.useEffect(() => {
    const associatedFloor = values.selectedLocation?.floor
    if (!!values.selectedLocation) {
      const findFloor = floors.find((floor) => floor.level === associatedFloor)
      setFieldValue('selectedFloor', findFloor)
      if (hasMultipleBuildings) {
        setFieldValue('selectedBuilding', findFloor)
      }
    }
  }, [values.selectedLocation, floors])

  // Main useEffect

  React.useEffect(() => {
    if (room) {
      setFieldValue('selectedLocation', room)
      if (!isFormSpain && !withIAServices && !withIAWithoutServices) {
        openCategoryPicker(room)
      }
    }
    getUserRequests()
    fetchFloors()

    if (user && site) {
      analytics.track({ screenName: analyticsKeys.incident, userType: user.type, currentSite: site, mySites, origin })
    }
  }, [i18n.lang])

  React.useEffect(() => {
    const beforeReload = (e: BeforeUnloadEvent) => {
      if (isFormTouched) {
        e.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', beforeReload)

    return () => window.removeEventListener('beforeunload', beforeReload)
  }, [isFormTouched])

  React.useEffect(() => {
    if (
      values.selectedCategory !== initialValues.selectedCategory ||
      values.attachments !== initialValues.attachments ||
      values.description !== initialValues.description ||
      (noLocation
        ? values.selectedFloor !== initialValues.selectedFloor
        : values.selectedLocation !== initialValues.selectedLocation)
    ) {
      setIsFormTouched(true)
      formStore.actions.setConfirmNavigation(true)
    } else {
      setIsFormTouched(false)
      formStore.actions.reset()
    }
  }, [values, noLocation])

  React.useEffect(() => {
    if (!!values.selectedCategory && withIAServices) {
      const findSuggestedCategory = suggestedCategories.find(
        (cat) => cat.categoryId === values.selectedCategory?.categoryId
      )
      if (!!findSuggestedCategory) {
        const findServices = imageCategories.find((cat) => cat.sysId === findSuggestedCategory.categoryId)
          ?.imageServices
        if (!!findServices) {
          const formattedServices = findServices.map((s) => ({
            id: s.id || s.sysId,
            name: s.name,
          }))
          setSuggestedServices(formattedServices)
        } else {
          setSuggestedServices([])
        }
      } else {
        setSuggestedServices([])
      }
    }
  }, [imageCategories, suggestedCategories, values.selectedCategory])

  const checkRoomRequest = () => {
    if (!!values.selectedLocation) {
      Modal.open(() => (
        <RoomRequests room={values.selectedLocation!} onNewRequest={handleSubmit} onCancel={resetForm} />
      ))
    } else {
      handleSubmit()
    }
  }

  const sendAnalyticsIA = (success: boolean) => {
    if (!!user) {
      const selectedServicesId = values.services.map((s) => s.id)
      analytics.track({
        screenName: analyticsKeys.incident,
        userType: user.type,
        currentSite: site,
        mySites,
        origin,
        incidentProps: {
          success,
          description: values.description === suggestedDescription ? 'IA' : 'User',
          typo: suggestedCategories.find((cat) => cat.categoryId === values.selectedCategory?.categoryId)
            ? 'IA'
            : 'User',
          service: suggestedServices.find((service) => selectedServicesId.includes(service.id)) ? 'IA' : 'User',
          image:
            !!values.imageIA ||
            !!values.attachments.find((att) => att.format === 'jpeg' || att.format === 'jpg' || att.format === 'png'),
        },
      })
    }
  }

  const resetForm = (selectedCategory?: Category) => {
    setValues(!!selectedCategory ? { ...initialValues, selectedCategory, selectedLocation: room } : initialValues)
    setNoLocation(false)
  }

  const spainFormType = React.useMemo(() => {
    if (!values.selectedCategory || !isFormSpain) {
      return undefined
    }
    resetForm(values.selectedCategory)
    const findFormType = Object.entries(config.interventionSysId).find((sys) =>
      sys[1].includes(values.selectedCategory!.categoryId)
    )
    if (!!findFormType) {
      return findFormType[0]
    }
    return undefined
  }, [values.selectedCategory])

  const isFormValid = React.useMemo(
    () =>
      isFormSpain && spainFormType
        ? isSpecificFormValid(values, spainFormType)
        : dirty &&
          status !== 'loading' &&
          !!values.description &&
          !!values.selectedCategory &&
          (noLocation ? !!values.selectedFloor : !!values.selectedLocation) &&
          ((!withServices && !withIAServices) || (values.services.length > 0 && !!values.priority)),
    [dirty, status !== 'loading', noLocation, withServices, spainFormType, values, withIAServices]
  )

  const getUserRequests = () => {
    if (user && site) {
      setStatus('loading')
      api
        .getMyInterventions(site.id, user.type)
        .then((res) => {
          setStatus('ok')
          setMyInterventions(res.incidents.sort((a, b) => (isAfter(new Date(a.date), new Date(b.date)) ? -1 : 0)))
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  const fetchFloors = () => {
    if (user && site) {
      setFloorsStatus('loading')
      api
        .getAllFloorLocations(site.id, user.type)
        .then((res) => {
          setFloorsStatus('ok')
          const newFloors = res.locations.sort((l1, l2) => l1.level - l2.level)
          setFloors(res.locations.sort((l1, l2) => l1.level - l2.level))
          if (newFloors.length === 1) {
            setFieldValue('selectedFloor', newFloors[0])
          }
        })
        .catch((err) => {
          Logger.error(err)
          setFloorsStatus('error')
        })
    }
  }

  const onUploadFile = (uploadEvent: React.ChangeEvent<HTMLInputElement>, imageIA?: boolean) => {
    if (uploadEvent.target.files && uploadEvent.target.files[0]) {
      const file = uploadEvent.target.files[0]
      const resizeSizeInMB = 1

      if (file.type.startsWith('image/')) {
        setIALoading(true)
        resizeImage(file, resizeSizeInMB)
          .then((newFile) => {
            checkFile(newFile, imageIA)
          })
          .catch(() => setIALoading(false))
      } else {
        checkFile(file, imageIA)
      }
    }
  }

  const checkFile = (file: File, imageIA?: boolean) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (readEvent) => {
      if (readEvent.target) {
        const imageFormat = Object.keys(imageMimeTypes).find(
          (key) => imageMimeTypes[key as AttachmentImageFormat] === file.type
        )
        const documentFormat = Object.keys(documentMimeTypesIntervention).find(
          (key) => documentMimeTypesIntervention[key as AttachmentDocumentFormatIntervention] === file.type
        )
        if (imageFormat || documentFormat) {
          const format = (imageFormat || documentFormat) as AttachmentImageFormat | AttachmentDocumentFormatIntervention

          const attachment: Attachment = {
            file: file,
            name: file.name,
            data: readEvent.target.result as string,
            format,
            type: !!imageFormat ? 'IMAGE' : 'DOCUMENT',
          }
          const fileSizeMo = file.size / (1024 * 1024)
          if (fileSizeMo > 5) {
            Modal.open(() => (
              <ValidationContent
                title={i18n.t('common.error')}
                description={i18n.t('screens.incident.form.maxSize')}
                onConfirm={Modal.close}
                confirmButtonTitle={i18n.t('common.ok')}
              />
            ))
          } else {
            if (imageIA) {
              setSelectedIAFile(file)
              setFieldValue('imageIA', attachment)
            } else {
              setFieldValue('attachments', [...values.attachments, attachment])
            }
          }
        }
      }
    }
  }

  const renderDatesRequests = (requests: RequestByDate) => (
    <GridCellContainer key={requests.date}>
      <DateTitle>{i18n.t('screens.incident.requestDate', { date: new Date(requests.date) })}</DateTitle>
      {requests.interventions.map((intervention, i, interventions) => (
        <UserRequestInfo
          key={intervention.id}
          intervention={intervention}
          isLast={interventions.length === i + 1}
          refreshIncidents={getUserRequests}
          floors={floors}
        />
      ))}
    </GridCellContainer>
  )

  const openPriorityPicker = () => {
    ToastAlert.open(() => (
      <PickerModalContent
        title={i18n.t('screens.incident.form.whichPriority')}
        options={priorityValues.map((v) => ({
          value: v,
          label: i18n.t(`screens.incident.form.requestPriorityValues.${v}`),
        }))}
        selectOption={(val) => {
          setFieldValue('priority', val)
          ToastAlert.close()
          if (!isFormSpain && !withIAServices && !withIAWithoutServices) {
            setTimeout(() => openDescriptionModal(), 200)
          }
        }}
        close={ToastAlert.close}
        goBack={
          isFormSpain
            ? undefined
            : () =>
                setTimeout(() => {
                  if (withIAServices || withIAWithoutServices) {
                    openRoomSelector()
                  } else {
                    openServicePicker()
                  }
                }, 200)
        }
      />
    ))
  }

  const renderCategoryPicker = () => (
    <FormValuePicker
      id="InterventionCategoryPicker"
      title={i18n.t('screens.incident.form.requestType')}
      onClick={() => openCategoryPicker()}
      placeholder={i18n.t('screens.incident.form.placeholder.chooseFromList')}
      value={values.selectedCategory?.subcategoryName}
      ariaLabel={i18n.t('label.ariaLabel.incident.categoryPicker')}
      mandatory
      withArrow
      disabled={!values.selectedLocation && !noLocation && !isFormSpain && !withIAServices && !withIAWithoutServices}
      suggestedCategories={suggestedCategories}
      onCategorySelected={(cat) => {
        setFieldValue('selectedCategory', cat)
        if (cat.categoryId !== values.selectedCategory?.categoryId) {
          setFieldValue('services', [])
        }
      }}
      selectedCategoryId={values.selectedCategory?.categoryId}
    />
  )

  const renderServicePicker = () => (
    <FormValuesPicker
      id="InterventionServicePicker"
      title={i18n.t('screens.incident.form.requestServices')}
      placeholder={i18n.t('screens.incident.form.placeholder.chooseFromList')}
      values={values.services.map((s) => ({ label: s.name, value: s.id }))}
      ariaLabel={i18n.t('label.ariaLabel.incident.servicePicker')}
      mandatory
      disabled={!values.selectedCategory}
      onClick={() => !!values.selectedCategory && openServicePicker()}
      removeOption={(val) =>
        setFieldValue(
          'services',
          values.services.filter((s) => s.name !== val)
        )
      }
      suggestedServices={suggestedServices}
      selectedServices={values.services}
      onServiceSelected={(service) => {
        const findService = values.services.find((s) => s.id === service.id)
        if (!findService) {
          setFieldValue('services', [...values.services, service])
        } else {
          setFieldValue(
            'services',
            values.services.filter((s) => s.id !== service.id)
          )
        }
      }}
    />
  )

  const openFloorSelector = (mode: 'BUILDING' | 'FLOOR', selectedBuilding?: APILocation) =>
    Modal.open(() => (
      <FloorSelector
        buildingSelected={mode === 'FLOOR' ? selectedBuilding || values.selectedBuilding : undefined}
        buildingMode={mode === 'BUILDING'}
        goBack={
          isFormSpain || mode === 'BUILDING' || (mode === 'FLOOR' && !hasMultipleBuildings)
            ? undefined
            : () => setTimeout(() => openFloorSelector('BUILDING'), 200)
        }
        setSelectedFloor={(l) => {
          if (mode === 'BUILDING') {
            setFieldValue('selectedBuilding', l)
            if (l.buildingId !== values.selectedBuilding?.buildingId) {
              setFieldValue('selectedFloor', undefined)
              setFieldValue('selectedLocation', undefined)
              // Si pas en mode espagne
              // Ouvrir la modale de choix d'étage
              if (!isFormSpain) {
                setTimeout(() => openFloorSelector('FLOOR', l), 200)
              }
            }
          } else {
            setFieldValue('selectedFloor', l)
            if (l.level !== values.selectedLocation?.floor) {
              setFieldValue('selectedLocation', undefined)
              // Si pas en mode espagne
              // Ouvrir la modale de localisation
              if (!isFormSpain) {
                setTimeout(() => {
                  openRoomSelector(undefined, l)
                }, 200)
              }
            }
            if (hasMultipleBuildings) {
              setFieldValue('selectedBuilding', l)
            }
          }
        }}
      />
    ))

  const openRoomSelector = (hideLocation?: boolean, selectedFloor?: APILocation) =>
    Modal.open(() => (
      <RoomSelector
        goBack={isFormSpain ? undefined : () => setTimeout(() => openFloorSelector('FLOOR'), 200)}
        setSelectedRoom={(l) => {
          Modal.close()
          setFieldValue('selectedLocation', l)
          // Si pas en mode espagne
          // Et type de demande pas encore renseigné
          // Ouvrir la modale de type de demande
          if (withIAServices) {
            setTimeout(() => {
              openPriorityPicker()
            }, 200)
          } else if (!isFormSpain && !values.selectedCategory && !withIAWithoutServices) {
            setTimeout(() => {
              openCategoryPicker(l)
            }, 200)
          }
        }}
        selectedFloor={selectedFloor || values.selectedFloor}
        selectedFloorBuilding={selectedFloor?.buildingName || values.selectedFloor?.buildingName}
        setNoLocation={() => {
          setNoLocation(true)
          // Si pas en mode espagne
          // Et type de demande pas encore renseigné
          // Ouvrir la modale de type de demande
          if (!isFormSpain && !values.selectedCategory && !withIAServices && !withIAWithoutServices) {
            setTimeout(() => openCategoryPicker(), 200)
          }
          if (withIAServices) {
            setTimeout(() => {
              openPriorityPicker()
            }, 200)
          }
        }}
        hideLocation={hideLocation}
        selectedBuilding={selectedFloor?.buildingName || values.selectedBuilding?.buildingName}
      />
    ))

  const openCategoryPicker = (selectedLocation?: Area) =>
    Modal.open(() => (
      <RequestTypeSelector
        goBack={
          isFormSpain || withIAServices || withIAWithoutServices
            ? undefined
            : () => setTimeout(() => openRoomSelector(), 200)
        }
        setSelectedCategory={(c) => {
          if (c.editorialPageIds.length > 0) {
            navigation.push(`/information/${c.editorialPageIds[0]}`)
          } else {
            if (c.categoryId !== values.selectedCategory?.categoryId) {
              setFieldValue('selectedCategory', c)
              setFieldValue('services', [])
              // Si pas en mode espagne et en mode avec services
              // Ouvrir la modale des services
              if (withIAServices && !values.imageIA) {
                setTimeout(() => openServicePicker(c, []), 200)
              } else if (withServices && !isFormSpain) {
                setTimeout(() => openServicePicker(c, []), 200)
              } else if (!withServices && !isFormSpain && !values.description) {
                setTimeout(() => openDescriptionModal(), 200)
              }
            }
          }
        }}
        selectedPOI={selectedLocation || values.selectedLocation}
      />
    ))

  const openServicePicker = (selectedCategory?: Category, selectedServices?: IncidentService[]) =>
    Modal.open(() => (
      <ServicesSelector
        goBack={
          isFormSpain || (withIAServices && !!values.imageIA)
            ? undefined
            : () => setTimeout(() => openCategoryPicker(), 200)
        }
        allServices={selectedCategory?.incidentServices || values.selectedCategory?.incidentServices || []}
        selectedServices={selectedServices || values.services}
        setSelectedServices={(s) => {
          setFieldValue('services', s)
          if (withIAServices && !values.imageIA) {
            setTimeout(() => openDescriptionModal(selectedCategory, selectedServices), 200)
          } else if (withServices && !isFormSpain && !withIAServices) {
            openPriorityPicker()
          }
        }}
      />
    ))

  const openDescriptionModal = (selectedCategory?: Category, selectedServices?: IncidentService[]) =>
    Modal.open(() => (
      <DescriptionModal
        goBack={
          isFormSpain
            ? undefined
            : () =>
                setTimeout(() => {
                  if (withIAServices) {
                    openServicePicker(selectedCategory, selectedServices)
                  } else if (withIAWithoutServices) {
                    openCategoryPicker()
                  } else if (withServices) {
                    openPriorityPicker()
                  } else {
                    openCategoryPicker()
                  }
                }, 200)
        }
        required
        setNewDescription={(newDescription) => {
          setFieldValue('description', newDescription)
          if ((withIAServices || withIAWithoutServices) && !values.imageIA) {
            setTimeout(() => {
              if (hasMultipleBuildings && !values.selectedBuilding) {
                openFloorSelector('BUILDING')
              } else if (!values.selectedFloor) {
                openFloorSelector('FLOOR')
              } else if (!values.selectedLocation) {
                openRoomSelector()
              } else if (withIAServices && !values.priority) {
                openPriorityPicker()
              } else {
                Modal.close()
              }
            }, 200)
          } else {
            Modal.close()
          }
        }}
      />
    ))
  const renderFloorPicker = () => (
    <>
      {hasMultipleBuildings && (
        <FormValuePicker
          id="InterventionRoomPicker"
          title={i18n.t('screens.incident.form.building')}
          onClick={() => openFloorSelector('BUILDING')}
          placeholder={i18n.t('screens.incident.form.placeholder.chooseFromList')}
          value={values.selectedBuilding ? values.selectedBuilding.buildingName : undefined}
          ariaLabel={i18n.t('label.ariaLabel.incident.roomPicker')}
          withArrow
        />
      )}
      <FormValuePicker
        id="InterventionRoomPicker"
        title={i18n.t('screens.incident.form.floor')}
        onClick={() => openFloorSelector('FLOOR')}
        placeholder={i18n.t('screens.incident.form.placeholder.chooseFromList')}
        value={
          values.selectedFloor
            ? `${
                hasMultipleBuildings && !values.selectedBuilding ? `${values.selectedFloor.buildingName} - ` : ''
              }${findLevelWording(values.selectedFloor.level, i18n, values.selectedFloor.levelCustomName)}`
            : undefined
        }
        ariaLabel={i18n.t('label.ariaLabel.incident.roomPicker')}
        mandatory
        withArrow
      />
    </>
  )

  const renderPriorityPicker = () => (
    <FormValuePicker
      id="InterventionPriorityPicker"
      title={i18n.t('screens.incident.form.requestPriority')}
      onClick={openPriorityPicker}
      placeholder={i18n.t('screens.incident.form.placeholder.chooseFromList')}
      value={i18n.t(`screens.incident.form.requestPriorityValues.${values.priority}`)}
      ariaLabel={i18n.t('label.ariaLabel.incident.requestPriority')}
      mandatory
      withArrow
    />
  )

  const renderDescriptionPicker = () => (
    <FormTextInput
      id="interventionDescription"
      name="description"
      onChange={handleChange}
      title={i18n.t('screens.incident.form.requestDescription')}
      value={values.description}
      error={touched.description ? errors.description : undefined}
      mandatory
      placeholder={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
      titleAriaLabel={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
    />
  )

  const renderLocationPicker = (hideLocation?: boolean) => (
    <FormValuePicker
      id="InterventionRoomPicker"
      title={i18n.t('screens.incident.form.location')}
      onClick={() => openRoomSelector(hideLocation)}
      placeholder={i18n.t('screens.incident.form.placeholder.chooseFromList')}
      value={values.selectedLocation?.name}
      ariaLabel={i18n.t('label.ariaLabel.incident.roomPicker')}
      mandatory
      withArrow
      disabled={!values.selectedFloor}
      footerContent={
        user && user.type === 'ENGIE' && !hideLocation ? (
          <RequestSwitchContainer>
            <SwitchLabel>{i18n.t('screens.incident.locationNotInList')}</SwitchLabel>
            <Switch value={noLocation} setValue={setNoLocation} />
          </RequestSwitchContainer>
        ) : undefined
      }
      mainContent={
        noLocation ? (
          <NoRoomMessageContainer>
            <NoRoomMessage>{i18n.t('screens.incident.form.addRoomNameDescription')}</NoRoomMessage>
          </NoRoomMessageContainer>
        ) : undefined
      }
    />
  )

  const renderAttachmentPicker = (mandatory?: boolean, withIA?: boolean) => {
    const maxAttachments = withIA ? 2 : 0

    return (
      <LabelAndInputContainer>
        <PhotoLabelContainer>
          <InputLabel mandatory={mandatory}>{i18n.t('screens.incident.form.reportAttachment')}</InputLabel>
        </PhotoLabelContainer>
        {values.attachments.map((a, index) => (
          <>
            <PictureContainer>
              <DeleteIcon
                onClick={() =>
                  setFieldValue(
                    'attachments',
                    values.attachments.filter((a, idx) => idx !== index)
                  )
                }>
                <Icons name="cross" size={20} />
              </DeleteIcon>
              {a.type === 'IMAGE' ? <IncidentPicture src={a.data} /> : <Icons name="news" size={50} />}
            </PictureContainer>
            <AttachmentLabel>{a.name}</AttachmentLabel>
          </>
        ))}
        {user?.type === 'ENGIE'
          ? values.attachments.length < (maxAttachments || MAX_INPUT_ENGIE) && (
              <AddPictureButton
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    hiddenFileInput.current.click()
                  }
                }}
                onClick={() => hiddenFileInput.current.click()}
                tabIndex={0}
                aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}
                disabled={withIA && !gdprAccepted}>
                <Icons name="plus" size={30} />
                <AddPictureInput
                  ref={hiddenFileInput}
                  type="file"
                  accept={[...Object.values(imageMimeTypes), ...Object.values(documentMimeTypesIntervention)].join(',')}
                  onChange={(evt) => onUploadFile(evt)}
                />
              </AddPictureButton>
            )
          : values.attachments.length < (maxAttachments || MAX_INPUT_EQUANS) && (
              <AddPictureButton
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    hiddenFileInput.current.click()
                  }
                }}
                tabIndex={0}
                aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}>
                <Icons name="plus" size={30} />
                <AddPictureInput
                  ref={hiddenFileInput}
                  type="file"
                  accept={[...Object.values(imageMimeTypes), ...Object.values(documentMimeTypesIntervention)].join(',')}
                  onChange={(evt) => onUploadFile(evt)}
                />
              </AddPictureButton>
            )}
      </LabelAndInputContainer>
    )
  }

  const renderDescriptionPickerSpecificForm = (
    formType: string,
    name: string,
    mandatory: boolean,
    value?: string,
    error?: string
  ) => (
    <FormTextInput
      id={name}
      name={name}
      onChange={handleChange}
      title={i18n.t(`screens.incident.form.spain.${formType}.${name}`)}
      value={value || ''}
      error={error}
      mandatory={mandatory}
      placeholder={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
      titleAriaLabel={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
    />
  )

  const renderTimePickerSpecificForm = (
    formType: string,
    name: string,
    mandatory: boolean,
    value: TimePickerDuration,
    onchange: (val: TimePickerDuration) => void
  ) => {
    const openPicker = () =>
      Modal.open(() => {
        return (
          <TimePickerInput
            value={value}
            onChange={onchange}
            close={Modal.close}
            title={i18n.t(`screens.incident.form.spain.${formType}.${name}`)}
          />
        )
      })

    return (
      <ItemContainer minWidth={100}>
        <InputCategoryTitle mandatory={mandatory}>
          {i18n.t(`screens.incident.form.spain.${formType}.${name}`)}
        </InputCategoryTitle>
        <InputValueContainer onClick={openPicker}>
          <InputValue>
            {i18n.t('screens.room.reservationTime', {
              hours: value.hours.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
              minutes: value.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
            })}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderDatePickerSpecificForm = (
    formType: string,
    name: string,
    mandatory: boolean,
    value: Date,
    onchange: (val: Date) => void,
    maxDate?: Date
  ) => {
    const openDatePicker = () =>
      Modal.open(() => {
        return (
          <CustomDatePicker
            selectedDate={value}
            onChange={onchange}
            minDate={NOW}
            maxDate={maxDate}
            closePicker={() => Modal.close()}
          />
        )
      })

    return (
      <ItemContainer minWidth={200}>
        <InputCategoryTitle mandatory={mandatory}>
          {i18n.t(`screens.incident.form.spain.${formType}.${name}`)}
        </InputCategoryTitle>
        <InputValueContainer onClick={openDatePicker}>
          <InputValue>
            {i18n.t('screens.room.reservationDate', {
              date: value,
            })}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderChoiceSelectorSpecificForm = (
    formType: string,
    name: string,
    options: TypeRequest[] | TypeAffection[] | TypeNumberPeople[] | TypeRooms[],
    mandatory: boolean,
    onchange: (val: string) => void,
    value?: string
  ) => {
    const openChoiceSelector = () =>
      Modal.open(() => (
        <ReservationTimeModal
          title={i18n.t(`screens.incident.form.spain.${formType}.${name}.title`)}
          options={options}
          setChoice={(newChoice) => onchange(newChoice)}
          typeModal="modal"
          type="intervention"
          formName={name}
          formType={formType}
        />
      ))

    return (
      <ItemContainer minWidth={200}>
        <InputCategoryTitle mandatory={mandatory}>
          {i18n.t(`screens.incident.form.spain.${formType}.${name}.title`)}
        </InputCategoryTitle>
        <InputValueContainer onClick={openChoiceSelector}>
          <InputValue isPlaceholder={!value}>
            {i18n.t(
              `screens.incident.form.${!value ? 'placeholder.chooseFromList' : `spain.${formType}.${name}.${value}`}`
            )}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderDirectorySpecificForm = (
    formType: string,
    name: string,
    mandatory: boolean,
    onchange: (val: UserData) => void,
    value?: UserData,
    withDefaultValue?: boolean,
    withoutEngieDirectory?: boolean
  ) => {
    const openDirectory = () =>
      Modal.open(() => (
        <Directory
          title={i18n.t(`screens.incident.form.spain.${formType}.${name}`)}
          onSelectContact={(newUserData) => {
            onchange(newUserData)
            Modal.close()
          }}
          engieDirectory={withoutEngieDirectory ? false : true}
        />
      ))
    return (
      <ItemContainer minWidth={300}>
        <InputCategoryTitle mandatory={mandatory}>
          {i18n.t(`screens.incident.form.spain.${formType}.${name}`)}
        </InputCategoryTitle>
        <InputValueContainer onClick={openDirectory}>
          <InputValue isPlaceholder={!value && !withDefaultValue}>
            {!value
              ? withDefaultValue
                ? `${user?.firstName} ${user?.lastName}`
                : i18n.t('screens.guest.searchContact')
              : `${value.firstname || ''} ${value.lastname}`}
          </InputValue>
          <IconContainer>
            <Icons name="chevron_bottom" color={Theme.colors.middleGrey} size={15} />
          </IconContainer>
        </InputValueContainer>
      </ItemContainer>
    )
  }

  const renderCheckDescription = () => (
    <CheckDescriptionContainer>
      <CheckDescriptionTitle>{i18n.t('screens.incident.form.checkDescription.title')}</CheckDescriptionTitle>
      <CheckDescriptionSubTitle>
        {i18n.t('screens.incident.form.checkDescription.description')}
      </CheckDescriptionSubTitle>
      <CheckDescriptionLabel>{i18n.t('screens.incident.form.requestDescription')} *</CheckDescriptionLabel>
      <CheckDescriptionContent>{values.description}</CheckDescriptionContent>
      <CheckDescriptionButtonContainer>
        <Button
          shadow
          label={i18n.t('screens.incident.form.checkDescription.submit')}
          onClick={() => {
            Modal.close()
            checkRoomRequest()
          }}
          disabled={!isFormValid}
          font={Theme.fonts.h3Bold}
        />
      </CheckDescriptionButtonContainer>
      <CheckDescriptionButtonContainer>
        <Button
          shadow
          label={i18n.t('common.cancel')}
          onClick={() => {
            setCheckDescription(false)
            Modal.close()
          }}
          color={Theme.colors.white}
          textColor={Theme.colors.blue}
          font={Theme.fonts.h3Bold}
        />
      </CheckDescriptionButtonContainer>
    </CheckDescriptionContainer>
  )

  const renderSpecificForm = () => {
    if (!spainFormType) {
      return null
    }
    if (spainFormType === 'type1') {
      return (
        <>
          {renderDescriptionPickerSpecificForm(spainFormType, 'entity', true, values.entity, errors.entity)}
          {renderDescriptionPickerSpecificForm(spainFormType, 'ceco', true, values.ceco, errors.ceco)}
          {renderDescriptionPickerSpecificForm(spainFormType, 'employeeId', true, values.employeeId, errors.employeeId)}
          {renderDirectorySpecificForm(
            spainFormType,
            'requester',
            true,
            (newUserData) => setFieldValue('requester', newUserData),
            values.requester,
            true
          )}
          {renderDirectorySpecificForm(
            spainFormType,
            'manager',
            true,
            (newUserData) => setFieldValue('manager', newUserData),
            values.manager
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type2') {
      return (
        <>
          {renderDirectorySpecificForm(
            spainFormType,
            'employee',
            true,
            (newUserData) => setFieldValue('employee', newUserData),
            values.employee
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type3') {
      return (
        <>
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'carLicensePlate',
            true,
            values.carLicensePlate,
            errors.carLicensePlate
          )}
          {renderDatePickerSpecificForm(
            spainFormType,
            'date',
            true,
            values.date,
            (newDate) => setFieldValue('date', newDate),
            addDays(NOW, 30)
          )}
          {renderTimePickerSpecificForm(spainFormType, 'time', true, values.time, (newHour) =>
            setFieldValue('time', newHour)
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'visitDuration',
            true,
            values.visitDuration,
            errors.visitDuration
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'visitorsNames',
            true,
            values.visitorsNames,
            errors.visitorsNames
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type4') {
      return (
        <>
          {renderDirectorySpecificForm(
            spainFormType,
            'requester',
            true,
            (newUserData) => setFieldValue('requester', newUserData),
            values.requester,
            true
          )}
          {renderDatePickerSpecificForm(spainFormType, 'entryDate', true, values.entryDate, (newDate) =>
            setFieldValue('entryDate', newDate)
          )}
          {renderTimePickerSpecificForm(spainFormType, 'entryTime', true, values.entryTime, (newHour) =>
            setFieldValue('entryTime', newHour)
          )}
          {renderDatePickerSpecificForm(spainFormType, 'departureDate', true, values.departureDate, (newDate) =>
            setFieldValue('departureDate', newDate)
          )}
          {renderTimePickerSpecificForm(spainFormType, 'departureTime', true, values.departureTime, (newHour) =>
            setFieldValue('departureTime', newHour)
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type5') {
      return (
        <>
          {renderDirectorySpecificForm(
            spainFormType,
            'callerId',
            true,
            (newUserData) => setFieldValue('callerId', newUserData),
            values.callerId
          )}
          {renderChoiceSelectorSpecificForm(
            spainFormType,
            'numberOfPeople',
            TYPE_NUMBER_PEOPLE_OPTIONS,
            true,
            (newChoice) => setFieldValue('numberOfPeople', newChoice),
            values.numberOfPeople
          )}
          {renderChoiceSelectorSpecificForm(
            spainFormType,
            'affection',
            TYPE_AFFECTION_OPTIONS,
            true,
            (newChoice) => setFieldValue('affection', newChoice),
            values.affection
          )}
          {renderDescriptionPickerSpecificForm(spainFormType, 'comment', true, values.comment, errors.comment)}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type6') {
      return (
        <>
          {renderDirectorySpecificForm(
            spainFormType,
            'employee',
            true,
            (newUserData) => setFieldValue('employee', newUserData),
            values.employee,
            false,
            true
          )}
          {renderDatePickerSpecificForm(spainFormType, 'entryDate', true, values.entryDate, (newDate) =>
            setFieldValue('entryDate', newDate)
          )}
          {renderDatePickerSpecificForm(spainFormType, 'estimatedEndDate', true, values.estimatedEndDate, (newDate) =>
            setFieldValue('estimatedEndDate', newDate)
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type7' || spainFormType === 'type10') {
      return (
        <>
          {spainFormType === 'type10' && renderFloorPicker()}
          {renderDescriptionPickerSpecificForm(spainFormType, 'company', true, values.company, errors.company)}
          {renderDatePickerSpecificForm(spainFormType, 'entryDate', true, values.entryDate, (newDate) =>
            setFieldValue('entryDate', newDate)
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'estimatedCheckInCheckOut',
            true,
            values.estimatedCheckInCheckOut,
            errors.estimatedCheckInCheckOut
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'visitorsNameAndSurnames',
            true,
            values.visitorsNameAndSurnames,
            errors.visitorsNameAndSurnames
          )}
          {renderDirectorySpecificForm(
            spainFormType,
            'responsible',
            true,
            (newUserData) => setFieldValue('responsible', newUserData),
            values.responsible
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'visitReason',
            true,
            values.visitReason,
            errors.visitReason
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type8') {
      return (
        <>
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'placeNumber',
            true,
            values.placeNumber,
            errors.placeNumber
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'numberPlate',
            true,
            values.numberPlate,
            errors.numberPlate
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    if (spainFormType === 'type9') {
      return (
        <>
          {renderChoiceSelectorSpecificForm(
            spainFormType,
            'typeRequest',
            TYPE_REQUEST_OPTIONS,
            true,
            (newChoice) => setFieldValue('typeRequest', newChoice),
            values.typeRequest
          )}
          {renderDirectorySpecificForm(
            spainFormType,
            'employee',
            true,
            (newUserData) => setFieldValue('employee', newUserData),
            values.employee
          )}
          {renderChoiceSelectorSpecificForm(
            spainFormType,
            'typeRooms',
            TYPE_ROOMS_OPTIONS,
            true,
            (newChoice) => setFieldValue('typeRooms', newChoice),
            values.typeRooms
          )}
          {renderDescriptionPickerSpecificForm(
            spainFormType,
            'requestReason',
            true,
            values.requestReason,
            errors.requestReason
          )}
          {renderAttachmentPicker()}
        </>
      )
    }
    return null
  }

  return (
    <MainContainer>
      <TitleHelmet title={i18n.t('screens.incident.title')} />
      <Breadcrumb screen="incident" path="incident" navigation={navigation} />

      <TopContainer>
        {!!showedBanner && <Banner banner={showedBanner} marginBottom={20} />}
        <SurveyBanner findFeature={findFeature} />
        <TitleAlignContainer>
          <Title>{i18n.t('screens.incident.title')}</Title>
          <ObligatoryTextContainer>
            <ObligatoryText>{i18n.t('screens.incident.obligatoryText')}</ObligatoryText>
          </ObligatoryTextContainer>
        </TitleAlignContainer>

        {floorsStatus === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : (
          <FormContainer>
            {(withIAServices || withIAWithoutServices) && (
              <LinearGradientContainer>
                <WhiteContainer>
                  <LeftContainer>
                    <GDPRContainer>
                      <SwitchLabel>{i18n.t('screens.incident.form.ia.gdpr')}</SwitchLabel>
                      <Switch
                        value={gdprAccepted}
                        setValue={(newValue: boolean) => {
                          setGdprAccepted(newValue)
                          if (!newValue && values.imageIA) {
                            setFieldValue('imageIA', undefined)
                          }
                        }}
                      />
                    </GDPRContainer>
                    <IADescriptionContainer>
                      <Icons name="magic" size={20} />
                      <AddIAPictureContainer
                        disabled={!gdprAccepted || isIALoading}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' && !isIALoading && gdprAccepted) {
                            hiddenFileInput.current.click()
                          }
                        }}
                        tabIndex={0}
                        aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}>
                        {!!values.imageIA ? (
                          <IncidentPicture src={values.imageIA.data} />
                        ) : (
                          <Icons
                            name="plus"
                            size={30}
                            color={gdprAccepted ? Theme.colors.turquoise : Theme.colors.mediumDarkGrey}
                          />
                        )}
                        <AddPictureInput
                          disabled={isIALoading || !gdprAccepted}
                          ref={hiddenFileInput}
                          type="file"
                          accept={[...Object.values(imageMimeTypes)].join(',')}
                          onChange={(evt) => onUploadFile(evt, true)}
                        />
                      </AddIAPictureContainer>
                    </IADescriptionContainer>
                    <InfosContainer>
                      {isIALoading && (
                        <ImageLoadingContainer>
                          <Loader type="TailSpin" color={Theme.colors.blue} height={24} width={24} />
                          <ImageLoading>{i18n.t('screens.incident.form.ia.imageLoading')}</ImageLoading>
                        </ImageLoadingContainer>
                      )}
                      <IADescription>{i18n.t('screens.incident.form.ia.addPhoto')}</IADescription>
                      <IACategoryContainer>{renderCategoryPicker()}</IACategoryContainer>
                      {withIAServices && <IACategoryContainer>{renderServicePicker()}</IACategoryContainer>}

                      {renderDescriptionPicker()}
                    </InfosContainer>
                  </LeftContainer>
                </WhiteContainer>
              </LinearGradientContainer>
            )}
            <RightContainer>
              {isFormSpain && renderCategoryPicker()}
              {isFormSpain && !!values.selectedCategory && withServices && !spainFormType && renderServicePicker()}
              {(!isFormSpain || (isFormSpain && !!values.selectedCategory && !spainFormType)) && renderFloorPicker()}
              {(!isFormSpain || (isFormSpain && !!values.selectedCategory && !spainFormType)) && renderLocationPicker()}
              {!isFormSpain && !withIAServices && !withIAWithoutServices && renderCategoryPicker()}
              {!isFormSpain && withServices && !withIAServices && !withIAWithoutServices && renderServicePicker()}
              {(!isFormSpain || (isFormSpain && !!values.selectedCategory && !spainFormType)) &&
                (withServices || withIAServices) &&
                renderPriorityPicker()}
              {(!isFormSpain || (isFormSpain && !!values.selectedCategory && !spainFormType)) &&
                !withIAServices &&
                !withIAWithoutServices &&
                renderDescriptionPicker()}
              {(!isFormSpain || (isFormSpain && !!values.selectedCategory && !spainFormType)) &&
                renderAttachmentPicker(undefined, withIAServices || withIAWithoutServices)}
              {isFormSpain && !!values.selectedCategory && !!spainFormType && renderSpecificForm()}
            </RightContainer>
          </FormContainer>
        )}
        <ButtonContainer>
          <Button
            noFlex
            type="submit"
            label={i18n.t('screens.incident.submitDemand')}
            verticalPadding={14}
            horizontalPadding={100}
            font={Theme.fonts.h3Bold}
            onClick={checkRoomRequest}
            disabled={!isFormValid || status === 'loading'}
            loading={status === 'loading'}
          />
        </ButtonContainer>
      </TopContainer>

      <BottomContainer isEmpty={!!myInterventions && myInterventions.length === 0}>
        <Title>{i18n.t('screens.incident.yourRequests')}</Title>

        {status === 'loading' ? (
          <LoaderContainer>
            <Loader type="TailSpin" color={Theme.colors.blue} />
          </LoaderContainer>
        ) : myInterventions && myInterventions.length > 0 ? (
          <GridContainer>{requestsSortedByDate && requestsSortedByDate.map(renderDatesRequests)}</GridContainer>
        ) : (
          <EmptyListContainer>
            <EmptyText>{i18n.t('screens.incident.emptyRequests')}</EmptyText>
            <EmptyImageContainer>
              <EmptyImageplaceHolder src={EmptyImagePlaceholder} aria-hidden />
            </EmptyImageContainer>
          </EmptyListContainer>
        )}
      </BottomContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    width: 100%;
  }
`
const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 50px 80px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 40px 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 30px 20px;
  }
`
const BottomContainer = styled('div')<{ isEmpty: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  height: 100%;
  ${(props) => props.isEmpty && 'min-height: 480px'};
  padding: 40px 0px 60px 102px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    padding: 40px 30px 60px 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`
const FormContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const LabelAndInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 350px;
  max-width: 450px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`
const PhotoLabelContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`

const TitleAlignContainer = styled('div')`
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const ObligatoryTextContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: 3px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 0px;
  }
`
const ButtonContainer = styled('div')`
  display: flex;
  margin: 40px 0px;
`
const GridContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
  }
`
const EmptyListContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const EmptyImageContainer = styled('div')`
  position: absolute;
  top: 0;
  left: calc(50% - 339px);
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    left: 40px;
    right: 40px;
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
`
const RequestSwitchContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
`
const GridCellContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: calc(100vw - 40px);
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.constants.shadow.card};
`
const NoRoomMessageContainer = styled('div')``

const PictureContainer = styled('div')`
  display: flex;
  position: relative;
  width: fit-content;
`
const DeleteIcon = styled('div')`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 5px 1px ${(props) => props.theme.colors.shadow};
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.title};
  margin: 0px;
`
const InputLabel = styled('label')<{ mandatory?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  ${(props) =>
    props.mandatory &&
    `
    ::after {
      color: ${props.theme.colors.red};
      content: "*";
      position: relative;
      left: 4px;
    }
  `}
`
const ObligatoryText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
`
const AddPictureInput = styled('input')`
  display: none;
`
const EmptyText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin-top: 19px;
`
const SwitchLabel = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-right: 10px;
`
const DateTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  padding: 8px 0px 7px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const NoRoomMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
`

const AttachmentLabel = styled('label')`
  width: 100%;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 10px 0px;
  text-overflow: ellipsis;
`

// AUTRES

const AddPictureButton = styled('button')<{ disabled?: boolean }>`
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.theme.colors.blue10p};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const EmptyImageplaceHolder = styled(SVG)`
  object-fit: fill;
  width: 678px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 100%;
  }
`
const IncidentPicture = styled('img')`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
`

const InputValueContainer = styled('button')`
  display: flex;
  flex: 1;
`

const InputValue = styled('p')<{ isPlaceholder?: boolean }>`
  ${(props) => (props.isPlaceholder ? props.theme.fonts.body : props.theme.fonts.bodyBold)};
  color: ${(props) => (props.isPlaceholder ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  display: flex;
  flex: 1;
`

const InputCategoryTitle = styled('label')<{ mandatory?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  ${(props) =>
    props.mandatory &&
    `
    ::after {
      color: ${props.theme.colors.red};
      content: "*";
      position: relative;
      left: 4px;
    }
  `}
`

const ItemContainer = styled('div')<{ minWidth?: number; noMarginTop?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  ${(props) => props.noMarginTop && 'margin-top: 0px;'}
  max-width: 400px;
  min-width: ${(props) => (props.minWidth ? props.minWidth : '300')}px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`

const IconContainer = styled('div')`
  padding-left: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinearGradientContainer = styled('div')`
  margin-right: 78px;
  background: linear-gradient(135deg, rgba(0, 188, 253) 0%, rgba(35, 210, 181) 100%);
  padding: 1px;
  width: 490px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 20px 0px;
    width: 100%;
  }
`

const WhiteContainer = styled('div')`
  background: ${(props) => props.theme.colors.white};
`

const LeftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, rgba(0, 188, 253, 0.04) 0%, rgba(35, 210, 181, 0.04) 100%);
  padding: 20px 16px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding: 20px 8px;
  }
`

const RightContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 50px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 35px;
    gap: 10px;
  }
`

const IADescriptionContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`

const IADescription = styled('p')`
  margin: 0;
  margin-bottom: 32px;
  ${(props) => props.theme.fonts.subtitleBold};
`

const AddIAPictureContainer = styled('label')<{ disabled: boolean }>`
  display: flex;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-left: 12px;
  border: 2px ${(props) => (props.disabled ? props.theme.colors.mediumDarkGrey : props.theme.colors.turquoise)} dotted;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 225px;
  width: calc(100% - 64px);
  background: ${(props) => props.theme.colors.white};
`

const InfosContainer = styled('div')`
  padding: 0 32px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 0 12px;
  }
`

const ImageLoadingContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  justify-content: center;
  align-items: center;
`

const ImageLoading = styled('p')`
  margin: 0;
  padding-left: 12px;
  ${(props) => props.theme.fonts.subtitle};
`

const IACategoryContainer = styled('div')`
  padding-bottom: 28px;
`

const GDPRContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`

const CheckDescriptionContainer = styled('div')`
  padding: 20px 48px;
  max-width: 530px;
`

const CheckDescriptionTitle = styled('p')`
  ${(props) => props.theme.fonts.h1Bold};
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
`

const CheckDescriptionSubTitle = styled('p')`
  margin-top: 20px;
  ${(props) => props.theme.fonts.subtitle};
  text-align: center;
`

const CheckDescriptionLabel = styled('p')`
  margin-top: 36px;
  ${(props) => props.theme.fonts.label};
`

const CheckDescriptionContent = styled('p')`
  margin-top: 10px;
  margin-bottom: 46px;
  ${(props) => props.theme.fonts.body};
`

const CheckDescriptionButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin-bottom: 16px;
`

export default InterventionScreen
