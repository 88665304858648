import React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as planningStore from 'planning/planningStore'
import * as bannersStore from 'banners/store'

import TitleHelmet from 'components/title/TitleHelmet'
import Icon from 'components/icons/Icons'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import SurveyBanner from 'components/banner/SurveyBanner'
import Banner from 'components/banner/Banner'
import StatusLabel from 'components/label/StatusLabel'
import DayPlannerItem from './DayPlannerItem'

import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import {
  formatUtcDate,
  getPlannerFeatures,
  filterBySelectedDate,
  INITIAL_BOOKINGS,
  getParkingType,
  getZapfloorGauges,
} from './utils'
import { findSharvyStatusColor, findStatusColor } from 'parking/utils'
import Logger from 'utils/Logger'

import { addDays, setHours, setMinutes, isToday, isBefore } from 'date-fns'

import accountApi from 'account/accountApi'
import parkingApi from 'parking/parkingApi'
import planningApi from 'planning/api'
import graphRoomApi from 'roombooking/api/graphRoomApi'
import roomApi from 'roombooking/api/roomApi'
import restaurantsApi from 'restaurants/api/restaurantsApi'

import { ParkingGauge, ParkingType, PlannerBookings, PlannerFeatureName, PlannerParkingBookings } from './types'
import { MeetingGraph } from 'roombooking/types'
import { getDateTime } from 'registration/utils'

const DayPlannerScreen = () => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const zapfloorUserId = useReducer(planningStore.store, (s) => s.zapfloorUserId)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const minSelectableDate = setHours(setMinutes(new Date(), 0), 0)

  const [selectedDate, setSelectedDate] = React.useState<Date>(minSelectableDate)
  const [bookings, setBookings] = React.useState<PlannerBookings>(INITIAL_BOOKINGS)
  const [allRooms, setAllRooms] = React.useState<Area[]>([])
  const [parkingType, setParkingType] = React.useState<ParkingType>()
  const [zapfloorGauges, setZapfloorGauges] = React.useState<ParkingGauge[]>([])
  const [barclapRestaurants, setBarclapRestaurants] = React.useState<Restaurant[]>([])

  const selectedDateBookings: PlannerBookings = React.useMemo(
    () => filterBySelectedDate(selectedDate, bookings, parkingType),
    [selectedDate, bookings, parkingType]
  )

  const maxSelectableDate = React.useMemo(
    () => addDays(setHours(setMinutes(minSelectableDate, 59), 23), site?.maxDeskBookingDays || 0),
    [minSelectableDate, site?.maxDeskBookingDays]
  )

  const filteredFeatures: ScreenDefinition[] = React.useMemo(
    () => getPlannerFeatures(!!site?.barclapId, site?.features),
    [site?.features]
  )

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'DAY_PLANNER'), [site])

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'DAY_PLANNER'),
    [banners]
  )

  const parkingRedirection = React.useMemo(
    () =>
      parkingType === 'VAMOS_PARKING' ||
      parkingType === 'ZAPFLOOR_PARKING' ||
      filteredFeatures.some((f) => f.name == 'PLANNING')
        ? 'planning'
        : parkingType === 'SHARVY_PARKING'
        ? 'sharvyParking'
        : 'parking',
    [filteredFeatures, parkingType]
  )

  React.useEffect(() => {
    if (!!user) {
      analytics.track({ screenName: analyticsKeys.dayPlanner, userType: user.type, currentSite: site, mySites })
    }
  }, [!!user])

  React.useEffect(() => {
    if (!!site && !!user) {
      // On récupère d'abord le type de parking du site (commuty, sharvy, vamos ou zapfloor)
      const currentParkingType = getParkingType(site.features)
      setParkingType(currentParkingType)

      // Puis on récupère les réservations selon les fonctionnalités activées sur le site
      fetchAllData(site, user.type, currentParkingType).then(setBookings)

      // On récupère aussi les salles de réunion pour les associer aux potentielles réservations
      roomApi.getAllRooms(site.id, user.type).then(({ areas }) => setAllRooms(areas))

      // On récupère les restaurants pour pouvoir associer l'id de la résa barclap à un restaurant
      restaurantsApi
        .getAllRestaurants(user.type, site.id)
        .then((res) => setBarclapRestaurants(res.restaurants.filter((r) => r.barclapId)))
    }
  }, [site, user?.type])

  React.useEffect(() => {
    if (!!site && !!site.locationId && !!user && !!zapfloorUserId) {
      const promises = bookings.parking.ZAPFLOOR_PARKING.map((booking) =>
        planningApi.zapfloor.getAvailableZones(
          user.type,
          site.id,
          booking.attributes.date_from,
          booking.attributes.hot_desk_items[0].unit_type_access_group_id,
          site.locationId!,
          booking.attributes.time_from,
          booking.attributes.time_to,
          zapfloorUserId,
          booking.attributes.hot_desk_items[0].unit_id
        )
      )
      Promise.all(promises)
        .then((zones) => setZapfloorGauges(getZapfloorGauges(Theme, zones[0])))
        .catch(handleError)
    }
  }, [site, user, zapfloorUserId, bookings.parking.ZAPFLOOR_PARKING])

  const fetchAllData = async (site: Site, userType: UserType, parkingType?: ParkingType) => {
    const allPlanningBookings =
      !!zapfloorUserId && !!site.locationId
        ? await planningApi.zapfloor
            .getBookingsList(userType, site.id, zapfloorUserId, formatUtcDate(i18n, minSelectableDate), site.locationId)
            .catch(handleError)
        : ([] as ZapfloorBooking[])

    return {
      parking: {
        PARKING_COMMUTY:
          parkingType === 'PARKING_COMMUTY'
            ? await parkingApi
                .getParkingBookings(site.id, userType)
                .then(({ requests }) => requests)
                .catch(handleError)
            : [],
        SHARVY_PARKING:
          parkingType === 'SHARVY_PARKING'
            ? await planningApi.sharvy
                .getUserInfos(userType, site.id)
                .then(({ bookings }) => bookings)
                .catch(handleError)
            : [],
        VAMOS_PARKING:
          parkingType === 'VAMOS_PARKING'
            ? await planningApi.vamos
                .getVamosBookings(userType, site.id)
                .then(({ vamosBookings }) => vamosBookings)
                .catch(handleError)
            : [],
        ZAPFLOOR_PARKING:
          parkingType === 'ZAPFLOOR_PARKING'
            ? allPlanningBookings.filter((b) => b.attributes.description === 'Parking')
            : [],
      },
      planning: allPlanningBookings.filter((b) => b.attributes.description !== 'Parking'),
      catering: !!site.barclapId
        ? await planningApi.barclap
            .getBookings(
              userType,
              site.id,
              site.barclapId,
              formatUtcDate(i18n, minSelectableDate),
              formatUtcDate(i18n, maxSelectableDate)
            )
            .catch(handleError)
        : ([] as BarclapBookingList),
      guest: await accountApi
        .getVisits(site.id, userType)
        .then(({ visits }) => visits)
        .catch(handleError),
      room: await graphRoomApi.monthEvents(userType, site.timezone).catch(handleError),
    } as PlannerBookings
  }

  const handleError = (err: any) => {
    Logger.error(err)
    return []
  }

  const sendModifyAnalytics = (isEmpty = false) =>
    analytics.track({
      screenName: analyticsKeys.dayPlanner,
      userType: user?.type,
      currentSite: site,
      mySites,
      action: isEmpty ? analyticsActionKeys.dayPlannerNewBooking : analyticsActionKeys.dayPlannerModifyBooking,
    })

  // PARKING RESERVATIONS
  const renderParkingList = (parkingBookings: PlannerParkingBookings) => {
    if (!!parkingType) {
      switch (parkingType) {
        case 'PARKING_COMMUTY':
          return parkingBookings.PARKING_COMMUTY.map((item) =>
            renderParkingData(
              item.parkingArea.name,
              findStatusColor(item.nextAllocationStatus, Theme),
              i18n.t(`screens.parking.status.${item.nextAllocationStatus}`)
            )
          )
        case 'SHARVY_PARKING':
          return parkingBookings.SHARVY_PARKING.filter((item) => !!item.spot?.name).map((item) =>
            renderParkingData(
              item.spot!.name!,
              findSharvyStatusColor(item.status, Theme),
              i18n.t(`screens.planning.sharvy.status.${item.status}`)
            )
          )
        case 'VAMOS_PARKING':
          return parkingBookings.VAMOS_PARKING.map((item) => renderParkingData(item.parkingPlace))
        case 'ZAPFLOOR_PARKING':
          return parkingBookings.ZAPFLOOR_PARKING.map((item) =>
            renderParkingData(
              item.attributes.zone_name,
              item.attributes.waiting_list ? Theme.colors.orange : Theme.colors.strongTurquoise,
              i18n.t(`screens.planning.booking.${item.attributes.waiting_list ? 'waiting' : 'confirmed'}`),
              item.attributes.hot_desk_id
            )
          )
      }
    }
  }

  const renderParkingData = (item: string, statusColor?: string, statusLabel?: string, id?: string) => {
    const gauge = zapfloorGauges.find((g) => g.id === id)
    return (
      <DayPlannerItem
        key={`${parkingType}-${item}`}
        Content={() => (
          <ItemData>
            <ItemTitle>{item}</ItemTitle>
            {!!gauge && (
              <ItemDataRow gap={10}>
                {renderIconLabel('users-wm', `${gauge.taken}/${gauge.max}`)}
                {renderIconLabel(gauge.iconChoice, `${Math.round(gauge.rate)}%`, undefined, gauge.occupancyColor)}
              </ItemDataRow>
            )}
            {!!statusColor && !!statusLabel && <StatusLabel color={statusColor} label={statusLabel} marginTop={6} />}
          </ItemData>
        )}
        buttonLabel={i18n.t('screens.day_planner.actions.modify')}
        onClick={() => {
          sendModifyAnalytics()
          window.open(`/${i18n.lang}/${parkingRedirection}`, '_blank')
        }}
      />
    )
  }

  // DESK RESERVATIONS
  const renderPlanningData = (item: ZapfloorBooking) => (
    <DayPlannerItem
      key={item.id}
      Content={() => (
        <ItemData>
          <ItemTitle>{item.attributes.hot_desk_name}</ItemTitle>
        </ItemData>
      )}
      buttonLabel={i18n.t('screens.day_planner.actions.modify')}
      onClick={() => {
        sendModifyAnalytics()
        window.open(`/${i18n.lang}/planning`, '_blank')
      }}
    />
  )

  // RESTAURANT SLOT RESERVATIONS
  const renderCateringData = (item: BarclapBooking) => {
    const findRestautant = barclapRestaurants.find((b) => b.barclapId === item.module_uuid)
    if (!findRestautant) {
      return null
    }
    return (
      <DayPlannerItem
        key={item.id}
        Content={() => (
          <ItemData>
            <ItemTitle>{item.name}</ItemTitle>
            {renderIconLabel(
              'clock',
              i18n.t('screens.day_planner.timeSlot', { start: new Date(item.start_time), end: new Date(item.end_time) })
            )}
          </ItemData>
        )}
        buttonLabel={
          isBefore(new Date(item.end_time), new Date()) ? undefined : i18n.t('screens.day_planner.actions.modify')
        }
        onClick={() => {
          sendModifyAnalytics()
          window.open(`/${i18n.lang}/catering/${findRestautant.id}?withBarclap=true`, '_blank')
        }}
      />
    )
  }

  // GUEST VISITS
  const renderGuestData = (item: PersonalVisit) => {
    const arrivalTime = getDateTime(item.arrivalTime)
    return (
      <DayPlannerItem
        key={item.visitId}
        Content={() => (
          <ItemData>
            <ItemTitle>{`${item.visitorFirstName} ${item.visitorLastName}`}</ItemTitle>
            {!!arrivalTime && renderIconLabel('clock', i18n.t('screens.day_planner.time', { time: arrivalTime }))}
          </ItemData>
        )}
        buttonLabel={isToday(selectedDate) ? undefined : i18n.t('screens.day_planner.actions.modify')}
        onClick={() => {
          sendModifyAnalytics()
          window.open(`/${i18n.lang}/guest?visitId=${item.visitId}`, '_blank')
        }}
      />
    )
  }

  // GRAPH MEETINGS (WITH OR WITHOUT ROOM)
  const renderMeetingData = (item: MeetingGraph) => (
    <MeetingContainer key={item.id}>
      <MeetingData>
        {renderIconLabel(
          'agenda',
          i18n.t('screens.day_planner.timeSlot', {
            start: new Date(item.start.dateTime),
            end: new Date(item.end.dateTime),
          }),
          true
        )}
        <ItemTitle>{item.subject}</ItemTitle>
      </MeetingData>

      {renderRoomData(item)}
    </MeetingContainer>
  )

  // ROOM RESERVATIONS
  const renderRoomData = (item: MeetingGraph) => {
    const isPast = isBefore(new Date(item.start.dateTime), new Date())

    if (isPast) {
      return null
    }

    const attendeeEmails = item.attendees.map((a) => a.emailAddress.address)
    const roomArea = allRooms.find((r) => !!r.office365Id && attendeeEmails.includes(r.office365Id))
    const roomStatus = item.attendees.find((a) => a.emailAddress.address === roomArea?.office365Id)?.status

    if (!roomArea || roomStatus?.response === 'declined') {
      return renderEmptyContent('room', item)
    }

    return (
      <DayPlannerItem
        key={item.id}
        Content={() => (
          <ItemData>
            <ItemTitle>{roomArea.name}</ItemTitle>
            <ItemDataRow gap={8}>
              {renderIconLabel(
                'building',
                roomArea.floorCustomName || i18n.t('screens.room.floorNumber', { floor: roomArea.floor })
              )}
              {renderIconLabel('user', i18n.t('screens.room.capacity', { count: item.attendees.length }))}
            </ItemDataRow>
          </ItemData>
        )}
        buttonLabel={i18n.t('screens.day_planner.actions.modify')}
        onClick={() => {
          sendModifyAnalytics()
          window.open(`/${i18n.lang}/room?openTab=myBookings`, '_blank')
        }}
        referentiel={roomArea}
      />
    )
  }

  const renderEmptyContent = (name: PlannerFeatureName, meeting?: MeetingGraph) => {
    const query = name === 'room' && !!meeting?.id ? `?meetingId=${meeting.id}` : ''
    const screen = name === 'parking' ? parkingRedirection : name
    const cateringDisabled = !isToday(selectedDate) && name === 'catering'

    return (
      <DayPlannerItem
        key={`${name}-empty`}
        contentLabel={i18n.t(`screens.day_planner.emptyFeatures.${name}${cateringDisabled ? 'Disabled' : ''}`)}
        buttonLabel={
          cateringDisabled ? undefined : i18n.t(`screens.day_planner.actions.${name === 'guest' ? 'register' : 'book'}`)
        }
        onClick={() => {
          sendModifyAnalytics(true)
          window.open(`/${i18n.lang}/${screen}${query}`, '_blank')
        }}
      />
    )
  }

  const renderContent = (name: PlannerFeatureName) => {
    const isEmptyData =
      name === 'parking'
        ? !parkingType || selectedDateBookings.parking[parkingType].length === 0
        : selectedDateBookings[name].length === 0
    if (isEmptyData) {
      return renderEmptyContent(name)
    }
    switch (name) {
      case 'parking':
        return renderParkingList(selectedDateBookings.parking)
      case 'planning':
        return selectedDateBookings.planning.map(renderPlanningData)
      case 'catering':
        return selectedDateBookings.catering.map(renderCateringData)
      case 'guest':
        return selectedDateBookings.guest.map(renderGuestData)
      case 'room':
        return selectedDateBookings.room.map(renderMeetingData)
    }
  }

  const renderFeature = (name: PlannerFeatureName, icon?: IconName) => (
    <React.Fragment key={name}>
      <FeatureHeader>
        {icon && <Icon name={icon} size={20} color={Theme.colors.blue} />}
        <FeatureTitle>{i18n.t(`screens.day_planner.features.${name}`)}</FeatureTitle>
      </FeatureHeader>
      <FeatureContent isRoom={name === 'room' && selectedDateBookings.room.length > 0}>
        {renderContent(name)}
      </FeatureContent>
    </React.Fragment>
  )

  const renderIconLabel = (icon: IconName, label: string, noMarginTop?: boolean, iconColor?: string) => (
    <ItemDataRow marginTop={noMarginTop ? 0 : 6}>
      <Icon name={icon} size={15} color={iconColor || Theme.colors.blue} />
      <ItemSubtitle>{label}</ItemSubtitle>
    </ItemDataRow>
  )

  return (
    <MainContainer>
      {!!showedBanner && (
        <TopContainer>
          <Banner banner={showedBanner} marginBottom={20} />
        </TopContainer>
      )}

      <TitleHelmet title={i18n.t('screens.day_planner.title')} />

      <SurveyBanner findFeature={findFeature} />

      <Content>
        <LeftContainer>
          <PlannerTitle>{i18n.t('screens.day_planner.calendar.title')}</PlannerTitle>
          <PlannerDescription marginBottom={36}>
            {i18n.t(`screens.day_planner.calendar.description`)}
          </PlannerDescription>
          <PlannerDescription marginBottom={16}>{i18n.t(`screens.day_planner.calendar.selectDate`)}</PlannerDescription>

          <CalendarContainer>
            <CustomDatePicker
              selectedDate={selectedDate}
              onChange={setSelectedDate}
              minDate={minSelectableDate}
              maxDate={maxSelectableDate}
              withPortal={false}
              withoutFocus={true}
            />
          </CalendarContainer>
        </LeftContainer>

        <RightContainer>
          <SummaryTitle>{i18n.t('screens.day_planner.daySummary.title')}</SummaryTitle>
          <SummaryDescription>{i18n.t(`screens.day_planner.daySummary.description`)}</SummaryDescription>

          {selectedDateBookings &&
            filteredFeatures.map((f) => renderFeature(f.name.toLowerCase() as PlannerFeatureName, f.icon))}
        </RightContainer>
      </Content>
    </MainContainer>
  )
}

export default DayPlannerScreen

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
`

const TopContainer = styled('div')`
  margin: 20px 20px 0;
`

const Content = styled('div')`
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    width: 100%;
  }
`

const LeftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 28px 32px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    position: relative;
  }
`

const CalendarContainer = styled('div')`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

const RightContainer = styled('div')`
  flex: 1;
  flex-direction: column;
  padding: 36px 0;
  border-left: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-top: 0;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-left: 0;
    border-right: 0;
    border-top: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  }
`

const FeatureHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
  gap: 4px;
  border: 0 solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-top-width: 1px;
  border-bottom-width: 1px;
`

const FeatureContent = styled('div')<{ isRoom: boolean }>`
  display: flex;
  ${(props) => props.isRoom && 'flex: 1'};
  flex-direction: column;
  ${(props) => !props.isRoom && 'align-items: flex-start'};
  padding: 16px 20px;
  gap: 16px;
`

const ItemData = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`

const ItemDataRow = styled('div')<{ marginTop?: number; gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap || 5}px;
  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`}px;
`

const MeetingContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`

const MeetingData = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 12px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    gap: 8px;
  }
`

// TEXTS

const PlannerTitle = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0 0 8px;
`

const PlannerDescription = styled('p')<{ marginBottom: number }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0 0 ${(props) => props.marginBottom}px;
`

const SummaryTitle = styled('h3')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0 20px 8px;
`

const SummaryDescription = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0 20px 24px;
`

const FeatureTitle = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  margin: 0;
`

const ItemTitle = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  margin: 0;
`

const ItemSubtitle = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  margin: 0;
`
